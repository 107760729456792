<template>
    <div class="orgcontainer">
        <h2> کارمزد و سطوح کاربری </h2>
        <div class="content">
            <p class="text">
                کارمزد معاملات لیوتیرکس به صورت درصد از مبلغ کل معامله است و محاسبه آن بر اساس ملاحظات زیر صورت می گیرد: <br />
                کارمزد خریدار ارزدیجیتال به صورت ارزدیجیتال کسر و کارمزد فروشنده ارزدیجیتال از مبلغ دریافتی به تومان کسر می شود. <br />
                کارمزد از هر دو سمت معامله کسر می شود. <br />
                در هنگام ثبت معاملات ، کارمزد به شما نمایش داده می شود. <br />
                لازم به ذکر است کارمزد معاملات جفت ارز (به طور مثال بیت کوین - تتر) به صورت ثابت ۰.۲% در نظر گرفته می شود.
            </p>
            <div class="levels">
                <p class="text">
                            با توجه به حجم معاملات سه ماه اخیر کاربر، کارمزد معاملات طبق جدول زیر به صورت پلکانی محاسبه می گردد:
                </p>
                <table>
                    <thead>
                        <tr>
                            <td style="text-align:start"> سطح کاربری </td>
                            <td style="text-align:center"> میزان معاملات سه ماه اخیر </td>
                            <td style="text-align:end"> کارمزد </td>
                        </tr>
                    </thead>
                    <tr class="tbody" v-for="(level,index) in levels" :key="index">
                            <td style="text-align:start"> {{ $level[level.userAccountLevel] }} </td>
                            <td style="text-align:center"> {{feeAmounts[index]}} </td>
                            <td style="text-align:end">% {{level.wagePercent}} </td>
                    </tr>
                </table>
            </div>
            <div class="cryptofee">
                <p class="text">
        کارمزد برداشت دارایی‌های دیجیتال به نوع ارز بستگی دارد. این هزینه در واقع به دلیل کارمزدهای تراکنش شبکه کسر می‌شود و لیوتیرکس در آن نقشی ندارد.<br /> در حال حاضر این هزینه‌ها به شرح زیر است:
                </p>
                <table>
                    <thead>
                        <tr>
                            <td style="text-align:start"> نوع ارز </td>
                            <td style="text-align:center"> حداقل میزان قابل برداشت  </td>
                            <td style="text-align:end"> کارمزد </td>
                        </tr>
                    </thead>
                    <tr class="tbody" v-for="(coin,index) in coins" :key="index">
                            <td style="text-align:start"> {{ $coinLabel[coin.coin] }} </td>
                            <td style="text-align:center"> {{coin.tokenTypeDetails[0].minWithdraw}} {{$coinUnit[coin.coin]}} </td>
                            <td style="text-align:end">% {{coin.tokenTypeDetails[0].withdrawalFee}} </td>
                    </tr>
                </table>
            </div>
            <div class="rialsfee">
                <h3> کارمزد برداشت ریالی </h3>
                <p>  
                            برداشت‌های ریالی برای تمامی حساب‌ها، در قالب انتقال پایا و یک بار در روز خواهد بود. بدیهی است مدت زمان انجام تسویه ریالی، بسته به زمان درخواست برداشت، در روزهای غیر تعطیل می‌تواند حداکثر تا ۲۴ ساعت به طول انجامد.

                </p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'Fees',
    data() {
        return {
            levels:[],
            coins:[],
            feeAmounts:['کمتر از ۲۰ میلیون تومان','۵۰-۲۰ میلیون تومان','۵۰-۱۵۰ میلیون تومان','بیش از ۱۵۰ میلیون تومان']
        }
    },
    methods:{
        async getLevels(){
            const res = await this.$axios.get('/users/account-levels');
            this.levels = res.filter(a => a.userAccountLevel !== "SUPERVISOR")
        },
        async getCoins(){
            const res = await this.$axios.get('/coins');
            this.coins=res.content.filter(a => a.p2pSupported ==true)
        }
    },
    mounted() {
        this.getLevels();
        this.getCoins();
    },
}
</script>

<style lang="scss" scoped>
.cryptofee{
    width: 100%;
}
.text{
    color: var(--black-fee);
    font-size: clamp(14px,2vw,18px);
}
table{
    border-radius: 12px;
    background: white;
    border-spacing: 10px;
}
.tbody
{
    color: var(--dark-grey);
    font-size: clamp(12px,2vw,14px);
}
thead
{
    color: var(--black-fee);
    font-size: clamp(14px,2vw,16px);
}
.content{
    width: 100%;
    background: var(--nwhite-nbackground);
    border-radius: 12px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    row-gap: 64px;
    color: var(--black-fee);
    font-size: clamp(14px,2vw,18px);
    z-index: 5;
}
.orgcontainer{
     &::before{
        width: 100%;
        height: 100%;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background: url('../../assets/feeBG.png');
        background-repeat: no-repeat;
        background-size: 100%;
    }
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    row-gap: 70px;
    padding: 100px 200px !important;
    h2{
        z-index: 5;
        align-self: center;
    }
    
}
</style>